@import "../../style/sass/main.scss";

.custom-error {
  margin-top: 0.2rem;
  color: $status-red;
  .srv-validation-message {
    text-transform: capitalize;
    font-size: 1rem;
  }
}
