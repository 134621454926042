// Logo Styling
.logo-3,
.logo-sc {
  font-size: 8rem;
  line-height: 13rem;
  font-weight: 700;
  // font-family: 'Montserrat';
}

.logo-3 {
  color: $primary-color;
}

.logo-sc {
  color: $secondary-color;
}

.remove-underline {
  text-decoration: none;
}

.w-100 {
  width: 100%;
}

.common-heading {
  color: $primary-color;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.section-subheading {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $text-disabled;
}

.grey-heading-500,
.grey-heading-600 {
  color: $grey-2;
  font-size: 1rem;
  line-height: 1.25rem
}

.grey-heading-500 {
  font-weight: 500;
}

.grey-heading-600 {
  font-weight: 600;
}

.section-breadcrumbs {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;

  .active {
    color: $primary-color;
  }
}

.section-breadcrumbs-a {
  color: $text-disabled;

  &:hover {
    color: $primary-color;
  }
}


// Mui Text Field Global Styling
fieldset.MuiOutlinedInput-notchedOutline {
  border-color: $border-color-2 !important;
}

label.MuiInputLabel-root,
.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused {
  color: $grey-1;
  z-index: 0;
}

.primary-button {
  background: $primary-color;
  color: $white-color;
  border-radius: 0.625rem;
  padding: 0.9375rem 1.1875rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;

  &:hover {
    color: $white-color;
  }
}

.secondary-button {
  background: $link-color-1;
  color: $primary-color;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 10px;
  padding: 1rem;
  font-weight: 600;
}

.cancel-button {
  border: 1px solid $primary-color;
  padding: 0.9375rem 1.375rem;
  color: $primary-color;
  line-height: 1.25rem;
  font-weight: 600;
  border-radius: 0.625rem;
}

.ml-20 {
  margin-left: 1.25rem !important; //20px
}

.ml-10 {
  margin-left: 0.625rem !important; //10px
}

.k-grid-header.k-header {
  color: $white-color;
  background: $primary-color;
  border: 1px solid $border-color;
  padding: 28px 21px;
}


// for phone number input
.phone-input.PhoneInput-focus {
  border: none;
}

.phone-input {
  border: 0.2rem solid $input-border-color;
  border-radius: 0.375rem;
  padding: 1rem;
  font-family: 'Euclid Circular Regular',
    sans-serif !important;

  input {
    border: none;
    font-family: 'Euclid Circular Regular', sans-serif !important;

    &:focus-visible {
      outline: none !important;
    }
  }
}

.bu-helper-text-phone-number {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: gray;
}

.phone-number-error-msg {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: red;
}

// for select input
.mySelect__control {
  border-radius: 0.8rem !important;
  border: 0.1rem solid $border-color-2 !important;
  box-shadow: none !important;
  font-size: 1.2rem;
  min-width: auto;
  min-height: 3.8rem !important;
}

.mySelect__indicator-separator {
  //color: $grey_3 !important;
  display: none !important;

}

.mySelect__indicator {
  padding: 0.5rem 1.2rem !important;
}

.mySelect__indicator:hover {
  color: $grey_3 !important;
  box-shadow: none !important;
}

.mySelect__value-container {
  height: 2rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;

  .mySelect__multi-value__label {
    background-color: $role-permission-background;
  }

  .mySelect__multi-value__remove {
    background-color: $role-permission-background;

    &:hover {
      background-color: $role-permission-cross-background;
      color: $primary-color;
    }
  }

  // overflow-y: scroll !important;
}

.mySelect__value-container::-webkit-scrollbar {
  width: 0.313rem;
}

.mySelect__placeholder {
  color: $grey_4 !important;
  font-family: 'Euclid Circular Regular', sans-serif;
}

.mySelect__single-value {
  padding: 0.2rem 0;
  font-size: 1.2rem;
  color: $grey_3 !important;
  font-family: 'Euclid Circular Regular', sans-serif;
}

.mySelect__input-container {
  margin: -0.1rem !important;
}

.mySelect__menu {
  z-index: 50 !important;

  .mySelect__option {
    font-size: 1.1rem !important;
  }

  .mySelect__option--is-focused:active {
    background-color: $grey-3;
  }

  .mySelect__option--is-selected {
    color: $primary-color;
    background-color: unset !important;
  }

  .css-10wo9uf-option:active {
    background-color: white !important;
  }
}

.mySelect__menu {
  .mySelect__menu-list {
    max-height: 130px;
  }
}

// disabled class
.my-profile-main {
  .disabled {
    background-color: $grey-5;
  }
}