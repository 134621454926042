@keyframes movesInLeft {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }

    80% {
        transform: translateX(10);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes movesInRight {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    80% {
        transform: translateX(-10);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes movesInBottom {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes movesInUp {
    0% {
        opacity: 0.2;
        transform: translateY(-75px);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}