@import "../../style/sass/main.scss";

.typing-text {
  display: inline-block;
  border-right: 1px solid $white-color; /* White cursor */
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  animation: blink-caret 0.7s step-end infinite;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
  color: $white-color;
  font-family: "Euclid Circular Bold", sans-serif;
}

.typing-text.deleting {
  transition: width 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $white-color;
  }
}

// three dot css styles
.loader-dot {
  width: 20px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#fff 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
}

.loader-dot-animated {
  animation: l3 1s infinite linear;
}

@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}
