@import "../../style/sass/main.scss";

.back-con {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;

  .back-text {
    font-size: 0.8rem;
  }
}