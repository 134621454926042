@import '../../style/sass/main.scss';

.scai-phone-input {
    border: 0.1rem solid $border-color-2;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    padding: 0.65rem 1.3rem;
    width: auto;
    height: auto;

    .PhoneInput--focus {
        border-color: transparent !important;
    }

    input {
        border: none;
        font-size: 1.2rem;
        color: $grey_3;
        width: 25rem;
        height: 2.5rem;
    }

    input::placeholder {
        font-size: 1.2rem;
    }

    input:focus {
        border-color: $border-color-2 !important;
        outline: none;
    }
}