@import "../../style/sass/main.scss";

.login-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, $primary-color 0%, $light-orange 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo-heading {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .paragraph {
    font-size: 1.2rem;
    color: $white-color;
    text-align: center;
    font-weight: 200;
  }

  .login-form {
    display: flex;
    align-items: center;
    justify-content: center;

    .email-con {
      transition: all 0.2s;
      animation: emailConComeLeftSide 0.2s ease-in-out;

      @keyframes emailConComeLeftSide {
        0% {
          transform: translateX(-20%);
          opacity: 0;
        }

        100% {
          transform: translateX(0);
          opacity: 1;
        }
      }

      .heading {
        line-height: 1;
        text-align: center;
        color: white;
        font-size: 4.3rem;
        min-width: 300px;
        font-family: "Euclid Circular Medium", sans-serif;
      }

      .input-box {
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding: 1.1rem 2rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 2px;
        gap: 1rem;
      }

      .or {
        font-size: 0.9rem;
        color: $white-color;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-family: "Euclid Circular Regular", sans-serif;
      }

      .input-base {
        color: $white-color;
        font-size: 1.3rem;
        flex-grow: 1;
        font-family: "Euclid Circular Light", sans-serif;
      }

      @keyframes shake {
        0% {
          transform: translateX(0);
        }

        25% {
          transform: translateX(-5px);
        }

        50% {
          transform: translateX(5px);
        }

        75% {
          transform: translateX(-5px);
        }

        100% {
          transform: translateX(0);
        }
      }

      input:-webkit-autofill {
        transition: all 0s 50000s;
      }

      /* Apply the shake animation to the input field */
      .input-error {
        animation: shake 0.3s ease;
        border: 1px solid $status-red;
        background: rgba(255, 255, 255, 0.4);
      }

      .indicator-box {
        background-color: $primary-color;
        width: 4rem;
        height: 2.5rem;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      button {
        padding: 1.42rem 2rem;
        background-color: $white-color;
        border-color: transparent;
        color: $black-color;
        font-size: 1.3rem;
        font-weight: 500;
        border-radius: 2px;
        font-family: "Euclid Circular Regular", sans-serif;
      }

      .okta-hovered {
        border: 1px solid $white-color;
        background: none;
        color: $white-color;
      }

      .okt-btn {
        border: 1px solid $white-color;
        background: none;
        color: $white-color;

        &:hover {
          background: $white-color;
          color: $black-color;
        }
      }

      .forgot-pass {
        text-align: right;
        letter-spacing: 1px;
        margin-top: 0.5rem;
        margin-bottom: 4rem;

        a {
          color: $white-color;
          font-family: "Euclid Circular Light", sans-serif;
        }
      }
    }
  }

  .com-logo {
    display: flex;
    justify-content: right;
    padding: 0 2rem 2rem 0;
  }
}

// .login-container {
//   width: 100%;
//   height: 100vh;
// }

// .login {
//   background-image: url(../../assets/login-bg.jpg);
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   .logo-container {
//     .logo-container-logo {
//       margin-bottom: 2.88rem;
//     }

//     .logo-img {
//       height: 10.13rem;
//       width: 10.5rem;
//       object-fit: contain;
//     }
//   }

//   .login-form {
//     width: 43.75rem;
//     display: flex;
//     flex-direction: column;
//     border: 0.3125rem solid rgba($color: #e5e8ec, $alpha: 1);
//     border-radius: 0.625rem;
//     padding: 2.5rem 3rem;
//     background: $white-color;

//     @media only screen and (max-width: 43.75rem) {
//       padding: 1rem 1rem;
//       width: 100%;
//     }
//   }

//   .login-input {
//     margin-bottom: 2rem;
//     font-family: "Montserrat", sans-serif;

//     .MuiInputBase-input.MuiInputBase-inputAdornedEnd {
//       padding: 1rem 1.16rem;
//     }
//   }

//   .login-heading {
//     margin-bottom: 0.5rem;
//     color: $secondary-color;
//     font-size: 2rem;
//     font-weight: 500;
//     font-family: "poppins", sans-serif;
//   }

//   .login-subheading {
//     margin-bottom: 3rem;
//     font-size: 1.25rem;
//     color: $text-color;
//     font-weight: 400;
//     font-family: "poppins", sans-serif;
//   }

//   .login-forgot {
//     width: 100%;
//     padding: 0 0 1.45rem 0;
//     text-align: right;

//     .login-forgot-link {
//       // color: $secondary-color;
//       font-weight: 500;

//       &:hover {
//         cursor: pointer;
//       }
//     }
//   }

//   .login-button {
//     background: $primary-color;
//     color: $white-color;
//     padding: 1.1rem 0;
//     font-size: 1.25rem;
//     font-weight: 700;
//     border-radius: 0.625rem;
//     border: none;
//     width: 100%;
//     line-height: 1.385rem;
//   }

//   .button-seperator {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-weight: 700;
//     font-size: 1rem;
//     margin: 0.8rem;
//   }
// }

// .otp-input-container {
//   margin: 2rem 0;

//   .otp-input {
//     border: 0.1px solid;
//     border-radius: 0.8rem;
//     width: 5.4rem !important;
//     height: 6rem !important;
//     font-size: 1.3rem;
//     font-weight: 600;
//     color: $black-color;
//     // caret-color: blue;
//     margin: 0rem 0.8rem;

//   }
// }