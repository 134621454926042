@import "../../style/sass/main.scss";

.image-container {
  position: relative;
  display: inline-block;
  // overflow: hidden;
  border-radius: 50%; /* Optional: makes the image circular */
  z-index: 1;
}

.image-container img {
  display: block;
  width: 100%;
  height: auto;
}

.image-container::before,
.image-container::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: white; /* Ripple color */
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: ripple 3s infinite;
  z-index: -1;
}

.image-container::before {
  width: calc(100% + 22px); /* 100px outside on each side */
  height: calc(100% + 22px);
  animation-delay: 1s; /* Offset the first ripple */
}

.image-container::after {
  width: calc(100% + 30px); /* 100px outside on each side */
  height: calc(100% + 30px);
  animation-delay: 2s; /* Offset the second ripple */
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.6;
  }
  70% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
