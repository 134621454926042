@import '../../style/sass/main.scss';

.main-container {
    height: 100vh;

    .layout-content-main {
        height: 100%;
        width: 100%;
        background: $white-color;
        overflow-y: auto;

        .navigation-container {
            background-color: $background;
            flex-direction: column;
            display: flex;
            position: relative !important;

            .child-container {
                background-color: $white-color;
            }

            .top-nav-section {
                padding-left: 2.5rem;
                padding-right: 2.5rem;

                .logo-section {
                    img {
                        object-fit: contain;
                        height: 4.8rem;
                    }
                }

                .logo-section,
                .logout-section {
                    padding-top: 0.3rem;
                }

                .logout-section {
                    gap: 1.5rem;
                    cursor: pointer;

                    svg {
                        scale: 0.8;
                    }

                    .logout-button {
                        margin-top: -1.6rem;
                    }

                    .header-logout-user {
                        .header-user-name {
                            font-weight: 600;
                            font-size: 1.1rem;
                            color: $white-color;
                            line-height: 1.375rem;
                        }

                        .bu-name {
                            color: $grey_3;
                            font-size: 1rem !important;
                        }
                    }
                }
            }

            .bottom-nav-section {
                padding: 0rem 1.1rem 2.5rem 2.5rem;
                transition: padding 0.5s ease;
                position: sticky;
                top: 0;
                background-color: $background;
                width: 100%;
            }

            .sticky-container {
                position: sticky;
                top: 0;
            }

            .collapsed-bottom-nav {
                padding-bottom: 0.2rem !important;
            }

            .extra-without-space {
                background-color: $white-color !important;
                height: 0rem;
                transition: height 0.6 ease;
            }

            .extra-space {
                height: 2rem !important;
            }

            .sub-menu-navigation {
                padding: 0 1rem;
                position: absolute;
                z-index: 1;
                right: 1%;
                top: 5rem;
                transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
                transform: translateY(-15px);
                opacity: 0;
            }

            .sub-menu-navigation.show {
                transform: translateY(0);
                opacity: 1;
            }

            .tabs-container {
                margin-top: -1rem;
                background-color: $grey_1;
                border-radius: 4rem;
                padding: 0.3rem;
                gap: 0.4rem;
                transition: border-radius 0.3s ease;
            }

            .default-tab {
                min-height: 1rem !important;

                .MuiTabs-indicator {
                    margin-bottom: 0.7rem;
                }

                .MuiTabs-indicator:after {
                    height: 0.1rem;
                }
            }

            .scrollable-tabs {
                max-width: 50vw;
                padding: 0rem 0.85rem;
                min-height: auto;
            }

            .animate-border-radius {
                animation: borderRadiusAnimation 0.3s ease forwards;
            }

            @keyframes borderRadiusAnimation {
                from {
                    border-radius: 4rem;
                    /* Initial radius value */
                }

                to {
                    border-radius: 2rem 2rem 0rem 0rem;
                    /* Final radius value */
                }
            }

            .tabs {
                font-size: 1.2rem;
                font-family: 'Euclid Circular Regular', sans-serif;
                font-weight: 400;
                color: $grey_3;
                border-radius: 4rem;
                text-transform: none;
                min-height: 0 !important;
            }

            .disabled-tab {
                // Custom disabled styling
                color: gray;
                pointer-events: auto; // Allow clicks even when it looks disabled
            }

            .is-removed {
                opacity: 0;
                height: 0;
                display: none;
                transform: translateX(-25vw);
            }

            .is-display-menu {
                // opacity: 0;
                // height: 0;
                display: block;
                transform: translateX(0vw);
            }

            /* MenuStyles.css */
            .fade-enter {
                opacity: 0;
            }

            .fade-enter-active {
                opacity: 1;
                transition: opacity 1000ms ease-in-out;
            }

            .fade-exit {
                opacity: 1;
            }

            .fade-exit-active {
                opacity: 0;
                transition: opacity 1000ms ease-in-out;
            }

            .padding-super {
                padding: 1rem 2rem;
            }

            .height-super {
                min-height: 0rem;
            }

            .avatar {
                width: 34px !important;
                height: 34px !important;
                font-size: 3.2rem !important;
            }

        }

        .scrollable-content {
            position: relative;
        }
    }
}